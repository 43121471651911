<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Productos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Productos</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_producto_export"
                        v-if="$store.getters.can('admin.productos.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-productos"
                        v-show="productos != null"
                        @click="abrirModal('Crear', null)"
                        v-if="$store.getters.can('admin.productos.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Producto
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Línea de Negocio
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.linea_negocio"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="linea_negocio in listasForms.lineas_negocio"
                            :key="linea_negocio.id"
                            :value="linea_negocio.id"
                          >
                            {{ linea_negocio.nombre }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Mercancía Transportada
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_producto"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_producto in listasForms.tipos_productos"
                            :key="tipo_producto.numeracion"
                            :value="tipo_producto.numeracion"
                          >
                            {{ tipo_producto.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Unidad de Medida
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.unidad_medida"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="unidad_medida in listasForms.unidades_medidas"
                            :key="unidad_medida.numeracion"
                            :value="unidad_medida.numeracion"
                          >
                            {{ unidad_medida.descripcion }}
                          </option>
                        </select>
                      </th>
                  
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="producto in productos.data" :key="producto.id">
                      <td>{{ producto.nombre }}</td>
                      <td>{{ producto.linea_negocio.nombre }}</td>
                      <td>{{ producto.nTipoProducto }}</td>
                      <td>{{ producto.nUnidadMedida }}</td>
                    
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-productos"
                            @click="abrirModal('Editar', producto.id)"
                            v-if="$store.getters.can('admin.productos.show')"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(producto.id)"
                            v-if="$store.getters.can('admin.productos.delete')"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="productos.total">
                  <p>
                    Mostrando del <b>{{ productos.from }}</b> al
                    <b>{{ productos.to }}</b> de un total:
                    <b>{{ productos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="productos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-productos">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="nombre">Producto</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="nombre"
                placeholder="Nombre"
                v-model="objProducto.nombre"
                :class="
                  $v.objProducto.nombre.$invalid ? 'is-invalid' : 'is-valid'
                "
                @blur="validaNombreProducto"
              />
              <div class="error" v-if="!$v.objProducto.nombre.required">
                Ingrese un nombre de producto
              </div>
            </div>
            <div class="form-group">
              <label for="linea_negocio_id">Línea de Negocio</label>
              <select
                id="linea_negocio_id"
                class="form-control form-control-sm"
                v-model="objProducto.linea_negocio_id"
                :class="
                  $v.objProducto.linea_negocio_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="linea_negocio in listasForms.lineas_negocio"
                  :key="linea_negocio.id"
                  :value="linea_negocio.id"
                >
                  {{ linea_negocio.nombre }}
                </option>
              </select>
              <div
                class="error"
                v-if="!$v.objProducto.linea_negocio_id.required"
              >
                Seleccione una Línea de negocio
              </div>
            </div>
            <div class="form-group">
              <label for="tipo_producto">Mercancía Transportada</label>
              <select
                id="tipo_producto"
                class="form-control form-control-sm"
                v-model="objProducto.tipo_producto"
                :class="
                  $v.objProducto.tipo_producto.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="tipo_producto in listasForms.tipos_productos"
                  :key="tipo_producto.numeracion"
                  :value="tipo_producto.numeracion"
                >
                  {{ tipo_producto.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objProducto.tipo_producto.required">
                Seleccione una Mercancía Transportada
              </div>
            </div>
            <div class="form-group">
              <label for="unidad_medida">Unidad de Medida</label>
              <select
                id="unidad_medida"
                class="form-control form-control-sm"
                v-model="objProducto.unidad_medida"
                :class="
                  $v.objProducto.unidad_medida.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="unidad_medida in listasForms.unidades_medidas"
                  :key="unidad_medida.numeracion"
                  :value="unidad_medida.numeracion"
                >
                  {{ unidad_medida.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objProducto.unidad_medida.required">
                Seleccione un Unidad de Medida
              </div>
            </div>
           
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.objProducto.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <ProductoExport ref="ProductoExport" />
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import ProductoExport from "./ProductoExport";

export default {
  name: "ProductoIndex",
  components: {
    pagination,
    ProductoExport,
  },
  data() {
    return {
      filtros: {
        nombre: null,
        linea_negocio: null,
        tipo_producto: null,
        unidad_medida: null,
      },
      productos: {},
      objProducto: {},
      listasForms: {
        lineas_negocio: [],
        tipos_productos: [],
        unidades_medidas: []
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },
  validations: {
    objProducto: {
      nombre: {
        required,
      },
      linea_negocio_id: {
        required,
      },
      tipo_producto: {
        required,
      },
      unidad_medida: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/productos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.productos = response.data;
        });
    },
    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },
    getTiposProductos() {
      axios.get("/api/lista/7").then((response) => {
        this.listasForms.tipos_productos = response.data;
      });
    },
    getUnidadesMedidas() {
      axios.get("/api/lista/8").then((response) => {
        this.listasForms.unidades_medidas = response.data;
      });
    },
    abrirModal(accion, idProductos) {
      this.getLineasNegocio();
      this.getTiposProductos();
      this.getUnidadesMedidas();
      this.modal.accion = accion;
      this.modal.title = accion + " Producto";
      if (accion == "Editar") {
        this.show(idProductos);
      } else {
        this.objProducto = {};
      }
    },
    validaNombreProducto() {
      if (this.objProducto.nombre) {
        let id = [];
        if (this.objProducto.id) {
          id.push(this.objProducto.id);
        }
        let filtro = {
          not_id: id,
          nombre_igual: this.objProducto.nombre,
        };
        axios
          .get("/api/admin/productos/lista", {
            params: filtro,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.$swal({
                icon: "error",
                title: "El producto digitado ya está creado...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.objProducto.nombre = null;
            }
          });
      }
    },
    save() {
      if (!this.$v.objProducto.$invalid) {
        axios
          .post("/api/admin/productos", this.objProducto)
          .then((response) => {
            this.$refs.closeModal.click();
            this.objProducto = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vualeva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    show(idProductos) {
      axios.get("/api/admin/productos/show/" + idProductos).then((response) => {
        this.objProducto = response.data;
      });
    },
    destroy(idProductos) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/admin/productos/" + idProductos)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getLineasNegocio();
    this.getTiposProductos();
    this.getUnidadesMedidas();
  },
};
</script>
